// 理论研究
<template>
  <div class="service-theory">
    <div class="theory-section">
      <h2 class="theory-title">无形资产十大类</h2>
      <el-table class="theory-table" :data="lyyjs" stripe style="width: 100%">
        <el-table-column prop="name" label="无形资产分类" width="230" align="center">
        </el-table-column>
        <el-table-column prop="describe" label="内容" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="theory-section">
      <h2 class="theory-title">知识产权评估四大应用场景</h2>
      <el-row class="theory-scene scene-list">
        <el-col class="scene-item" :span="6" v-for="item in scenes" :key="item.id">
          <img :src="$base+item.picture" alt="">
          <p class="scene-title">{{item.name}}</p>
          <p class="scene-info">{{item.describe}}</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getCore } from '@/api'
export default {
  data () {
    return {
      lyyjs: [],
      scenes: []
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        let { data } = await getCore('llyj')
        this.lyyjs = data
      } catch (error) {
        console.log("网络错误");
      }
      try {
        let { data } = await getCore('zscqpgyycj')
        this.scenes = data
      } catch (error) {
        console.log("网络错误");
      }
    }
  },
};
</script>

<style scoped lang="less">
.theory-section {
  margin-bottom: 78px;
  .theory-title {
    font-size: 28px;
    color: #333333;
    text-align: center;
    margin-bottom: 38px;
  }
}

/deep/ .theory-table {
  border-radius: 17px;

  .el-table__header {
    th {
      height: 76px;
      background-color: #fbd690;
      font-size: 18px;
      border: none;
      &.el-table_1_column_1 {
        border-right: 2px solid #fff;
      }
    }
  }
  .el-table__body {
    td {
      height: 64px;
      border: none;
      font-size: 16px;
      &.el-table_1_column_1 {
        border-right: 2px solid #fff;
      }
      &.el-table_1_column_2 {
        color: #333333;
      }
    }

    .el-table__row--striped {
      td {
        background-color: #eeebeb;
      }
    }
  }
  .el-table_1_column_1,
  th {
    font-weight: bold;
    color: #000000;
  }
}

.theory-scene {
  .scene-item {
    text-align: center;
    img {
      display: inline-block;
    }
    p {
      width: 88%;
      margin: 18px auto;
      &.scene-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>
